var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height flex-nowrap pa-0 flex-column align-center justify-center ma-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"flex-grow-0 transparent",style:({
      width: '100%'
    }),attrs:{"flat":""}},[_c('CreatePlaylistDialog',{attrs:{"playlists":_vm.playlists,"selectedPlaylist":_vm.loadedPlaylist,"users":_vm.users},on:{"created":_vm.playlistCreated}}),(_vm.loadedPlaylist && _vm.customPlaylist && !_vm.playlistLoading)?[(!_vm.loadedPlaylist.deleted)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","icon":_vm.$vuetify.breakpoint.smAndDown},on:{"click":_vm.editPlaylist}},[_c('v-icon',{class:{ 'mr-2': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v("mdi-pencil")]),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._v(" "+_vm._s(_vm.$t('app.playlists.actions.edit'))+" ")]:_vm._e()],2):_vm._e(),(!_vm.loadedPlaylist.deleted)?_c('DeleteDialog',{attrs:{"playlist":_vm.loadedPlaylist},on:{"deleted":_vm.playlistDeleted}}):_c('v-btn',{staticClass:"mr-4",attrs:{"loading":_vm.restoreLoading,"color":_vm.$vuetify.theme.dark ? 'cc-dark' : undefined},on:{"click":_vm.restorePlaylist}},[_c('v-icon',{class:{ 'mr-2': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v("mdi-restore")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.actions.restore'))+" ")],1)]:_vm._e()],2),_c('v-container',{staticClass:"fill-height flex-column flex-nowrap justify-center pa-4",attrs:{"fluid":""}},[(_vm.loadedPlaylist)?[_c('div',{staticClass:"pl-3 pb-3 mr-auto"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.loadedPlaylist.name)+" ")]),(!_vm.playlistLoading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({style:({
                cursor: 'default'
              })},on),[_vm._v(" ("+_vm._s(_vm.totalDuration)+") ")])]}}],null,false,3649875475)},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.duration'))+" ")]):_vm._e(),(!_vm.playlistLoading && _vm.customPlaylist)?_c('EditDialog',{attrs:{"playlist":_vm.loadedPlaylist},on:{"updated":_vm.playlistUpdated}}):_vm._e()],1),(
          _vm.customPlaylist &&
            _vm.loadedPlaylist.description &&
            _vm.loadedPlaylist.description.length > 0
        )?_c('div',{staticClass:"pl-3 pb-2 mr-auto body-2"},[_vm._v(" "+_vm._s(_vm.loadedPlaylist.description)+" ")]):_vm._e(),_c('TracksTable',{staticClass:"elevation-2 flex-grow-1 fill-height",attrs:{"tracks":_vm.tracks,"overridePlay":true,"uniqueTracks":false,"playlistId":_vm.loadedPlaylist.id,"loading":_vm.playlistLoading},on:{"play":_vm.playPlaylist}})]:_c('div',{staticClass:"display-1",style:({
        textAlign: 'center',
        userSelect: 'none',
        opacity: '0.2'
      })},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.notSelected'))+" ")])],2),_c('portal',{attrs:{"to":"drawer"}},[_c('PlaylistsMenu',{attrs:{"officialPlaylists":_vm.officialPlaylists,"customPlaylists":_vm.customPlaylists,"selectedPlaylist":_vm.selectedPlaylistId,"users":_vm.users,"layout":_vm.layout},on:{"userSelect":_vm.selectUser,"update:selectedPlaylist":function($event){_vm.selectedPlaylistId=$event},"update:selected-playlist":function($event){_vm.selectedPlaylistId=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }