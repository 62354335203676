




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Playlist } from '../../../service/types';
import { removePlaylist } from '../../../service/api';
import { errorEventBus } from '../../../service';

@Component
export default class DeleteDialog extends Vue {
  @Prop({ required: true })
  playlist!: Playlist;

  deleteDialog: boolean = false;
  deleteLoading: boolean = false;

  async removePlaylist() {
    if (!this.playlist) {
      return;
    }

    this.deleteLoading = true;
    try {
      const id = this.playlist.id;
      await removePlaylist(id);

      this.$emit('deleted');
      this.deleteDialog = false;
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.deleteLoading = false;
    }
  }
}
