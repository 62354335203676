





































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PlaylistsMenu from '@/components/drawer/PlaylistsMenu.vue';
import { getModule } from 'vuex-module-decorators';
import { GuiModule } from '@/store/modules/gui';
import { Playlist, ClassType, Track } from '@/service/types';
import {
  getPlaylists,
  getTracks,
  getPlaylist,
  addPlaylist,
  removePlaylist,
  restorePlaylist,
  User,
  getUsers,
getLayout
} from '@/service/api';
import { UserModule } from '@/store/modules/user';
import TracksTable from '@/components/TracksTable.vue';

import CreatePlaylistDialog from '@/components/CreatePlaylistDialog.vue';
import DeleteDialog from './DeleteDialog.vue';
import EditDialog from './EditDialog.vue';
import { formatSeconds } from '../../../util';
import { PlayerModule } from '../../../store/modules/player';
import { getPlayer } from '../../../service/audio';
import { errorEventBus } from '../../../service';
import { EditorModule } from '@/store/modules/editor';
import { LayoutRoot, resolveRoot } from '@/service/layout/runtime';

@Component({
  components: {
    CreatePlaylistDialog,
    DeleteDialog,
    EditDialog,
    PlaylistsMenu,
    TracksTable
  }
})
export default class Manage extends Vue {
  playlists: Playlist[] = [];

  selectedPlaylistId: string | null = null;
  loadedPlaylist: Playlist | null = null;
  playlistLoading: boolean = false;

  tracks: Track[] | null = null;
  users: User[] = [];

  layout: LayoutRoot | null = null;

  restoreLoading: boolean = false;

  get officialPlaylists() {
    return this.playlists.filter(
      p => p.classTypes.indexOf(ClassType.Custom) === -1
    );
  }

  get customPlaylists() {
    return this.playlists.filter(
      p =>
        p.classTypes.indexOf(ClassType.Custom) !== -1 &&
        (!this.admin || p.userId === this.selectedUserId)
    );
  }

  get customPlaylist(): boolean {
    return (
      this.loadedPlaylist?.classTypes.indexOf(ClassType.Custom) !== -1 ?? false
    );
  }

  get totalDuration(): string | undefined {
    if (!this.tracks) {
      return;
    }

    return formatSeconds(
      this.tracks?.reduce((total, t) => total + (t.duration ?? 0), 0)
    );
  }

  get admin() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.admin;
  }

  get selectedUserId() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.selectedUserId;
  }

  editPlaylist() {
    const playlists = getModule(EditorModule, this.$store);

    // TODO this is ugly af
    playlists.setTargetPlaylistId('');
    playlists.setHistory([]);
    playlists.setLastSaved(null);
    playlists.setTargetPlaylist(null);
    playlists.setTargetPlaylistTracks([]);

    this.$router.push({
      name: 'editPlaylist',
      params: {
        playlistId: this.loadedPlaylist!.id
      }
    });
  }

  async selectUser(userId?: string) {
    if (userId) {
      const userStore = getModule(UserModule, this.$store);

      userStore.setSelectedUserId(userId ?? null);

      await this.getPlaylists();
    }
  }

  async getUsers() {
    if (!this.admin) {
      return;
    }

    this.$set(this, 'users', await getUsers());
  }

  async playPlaylist(trackId?: string, index?: number) {
    if (!this.tracks) {
      return;
    }

    const playerStore = getModule(PlayerModule, this.$store);

    playerStore.setTracks(this.tracks);
    playerStore.setcurrentTrackIndex(index ?? 0);
    playerStore.setPlaylist(this.loadedPlaylist);

    const player = await getPlayer();
    await player.load(this.tracks[index ?? 0]);
    await player.play();
  }

  async playlistCreated(id: string) {
    await this.getPlaylists();

    this.selectedPlaylistId = this.playlists.find(p => p.id === id)!.id;
  }

  async playlistDeleted() {
    await this.getPlaylists();
    await this.reloadPlaylist();
  }

  async playlistUpdated() {
    await this.getPlaylists();
    await this.reloadPlaylist();
  }

  async getPlaylists() {
    const userId = this.selectedUserId;

    this.playlists = await getPlaylists(userId ?? undefined);
  }

  async getLayout() {
    this.layout = resolveRoot(await getLayout());
  }

  @Watch('selectedPlaylistId')
  onSelectedPlaylistChanged() {
    if (this.selectedPlaylistId) {
      if (this.$route.params.playlistId !== this.selectedPlaylistId) {
        this.$router.push({
          name: 'managePlaylists',
          params: {
            playlistId: this.selectedPlaylistId
          }
        });
      }

      return this.loadPlaylist(this.selectedPlaylistId);
    }

    if (this.$route.params.playlistId) {
      this.$router.push({
        name: 'managePlaylists'
      });
    }
    this.loadedPlaylist = null;
  }

  async loadPlaylist(playlistId: string) {
    this.loadedPlaylist = this.playlists.find(p => p.id === playlistId) ?? null;

    this.playlistLoading = true;
    try {
      if (this.loadedPlaylist) {
        this.loadedPlaylist = await getPlaylist(this.loadedPlaylist.id);
        this.tracks = await getTracks(...this.loadedPlaylist.tracks);
      } else {
        this.tracks = null;
      }
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.playlistLoading = false;
    }
  }

  async reloadPlaylist() {
    if (!this.selectedPlaylistId) {
      return;
    }
    await this.loadPlaylist(this.selectedPlaylistId);
  }

  async restorePlaylist() {
    if (!this.loadedPlaylist) {
      return;
    }

    this.restoreLoading = true;
    try {
      const id = this.loadedPlaylist.id;
      await restorePlaylist(id);

      await this.getPlaylists();
      await this.reloadPlaylist();
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.restoreLoading = false;
    }
  }

  async mounted() {
    const gui = getModule(GuiModule, this.$store);
    gui.setDrawerEnabled(true);

    await this.getUsers();
    await this.getPlaylists();
    await this.getLayout();

    if (this.$route.params.playlistId) {
      this.selectedPlaylistId = this.$route.params.playlistId;
    }
  }

  beforeDestroy() {
    const gui = getModule(GuiModule, this.$store);

    gui.setDrawerEnabled(false);
  }
}
