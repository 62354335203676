

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Playlist } from '../../../service/types';
import { removePlaylist, updatePlaylist } from '../../../service/api';
import { clone } from '../../../util';
import { errorEventBus } from '../../../service';

@Component
export default class EditDialog extends Vue {
  @Prop({ required: true })
  playlist: Playlist | undefined;

  editDialog: boolean = false;
  editLoading: boolean = false;

  editPlaylistName: string = '';
  editValid: boolean = false;
  editDescription: string = '';

  @Watch('playlist')
  onPlaylistChanged() {
    this.updateData();
  }

  updateData() {
    this.editPlaylistName = this.playlist?.name ?? '';
    this.editDescription = this.playlist?.description ?? '';
  }

  async updatePlaylist() {
    if (!this.playlist) {
      return;
    }

    const playlist = clone(this.playlist);
    playlist.name = this.editPlaylistName;
    playlist.description = this.editDescription;

    this.editLoading = true;
    try {
      await updatePlaylist(playlist);

      this.$emit('updated');
      this.editDialog = false;

      this.resetEditForm();
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.editLoading = false;
    }
  }

  resetEditForm() {
    this.updateData();
  }

  created() {
    this.updateData();
  }
}
