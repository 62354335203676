














































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  Playlist,
  classTypes,
  playlistLengths,
  ClassType,
  classColors
} from '../../service/types';

import ClassIcon from '@/components/ClassIcon.vue';
import LengthIcon from '@/components/LengthIcon.vue';
import { User } from '@/service/api';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/modules/user';
import { Layout, LayoutRoot } from '@/service/layout/runtime';

interface TreeItem {
  [key: string]: any;
  key: string;
  children?: TreeItem[];
}

@Component({
  components: {
    ClassIcon,
    LengthIcon
  }
})
export default class PlaylistsMenu extends Vue {
  @Prop(Array)
  officialPlaylists?: Playlist[];

  @Prop(Array)
  customPlaylists?: Playlist[];

  @Prop(String)
  selectedPlaylist?: string;

  @Prop()
  layout?: LayoutRoot;

  @Prop()
  users?: User[];

  officialTree: string[] = [];
  officialTreeOpen: string[] = [];
  selectedOfficialPlaylists: string[] = [];

  customTree: string[] = [];
  customTreeOpen: string[] = [];
  selectedCustomPlaylists: string[] = [];

  playlistSearch: string = '';
  panelsOpen: number[] = [];

  showDeleted: boolean = false;

  get selectedUserId() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.selectedUserId;
  }

  get officialItems(): TreeItem[] {
    if (!this.officialPlaylists || !this.layout) {
      return [];
    }

    return this.transformLayout(this.officialPlaylists, this.layout.layout);
  }

  get customItems(): TreeItem[] {
    if (!this.customPlaylists) {
      return [];
    }

    return this.customPlaylists
      .filter(playlist => !(!this.showDeleted && playlist.deleted))
      .map(playlist => {
        return {
          key: playlist.id,
          label: playlist.name
        };
      });
  }

  get admin() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.admin;
  }

  @Watch('selectedPlaylist')
  onSelectedPlaylistChanged() {
    this.selectPlaylist();
  }

  // This is for selection from the parent
  selectPlaylist() {
    if (this.selectedPlaylist === undefined || this.selectedPlaylist === null) {
      return;
    }

    const official: boolean = !!this.officialPlaylists?.find(
      p => p.id === this.selectedPlaylist
    );

    this.setPlaylistsSelection([this.selectedPlaylist], official);
  }

  // This actually sets the tree selections
  setPlaylistsSelection(playlists: string[], official: boolean) {
    if (official) {
      this.$set(this, 'selectedOfficialPlaylists', playlists);
      this.$set(this, 'selectedCustomPlaylists', []);
    } else {
      this.$set(this, 'selectedCustomPlaylists', playlists);
      this.$set(this, 'selectedOfficialPlaylists', []);
    }
  }

  // This is registered in the trees
  selectPlaylists(playlists: string[], official: boolean) {
    if (
      playlists.length === 0 &&
      ((official && this.selectedCustomPlaylists.length !== 0) ||
        (!official && this.selectedOfficialPlaylists.length !== 0))
    ) {
      this.setPlaylistsSelection([], true);
      this.setPlaylistsSelection([], false);
      return;
    }

    this.setPlaylistsSelection(playlists, official);

    this.$emit('update:selectedPlaylist', playlists[0]);
  }

  autoOpenSearch() {
    // Close everything
    if (this.playlistSearch.length === 0) {
      this.$set(this, 'panelsOpen', []);
      (this.$refs.officialTree as any)?.updateAll(false);
      (this.$refs.customTree as any)?.updateAll(false);
      return;
    }

    let panelsCount = 0;
    if (this.officialPlaylists) {
      panelsCount++;
    }

    if (this.customPlaylists) {
      panelsCount++;
    }

    const panelsOpen: number[] = [];

    if (panelsCount > 0) {
      for (let i = 0; i <= panelsCount; i++) {
        panelsOpen.push(i);
      }
    }

    this.$set(this, 'panelsOpen', panelsOpen);
    (this.$refs.officialTree as any)?.updateAll(true);
    (this.$refs.customTree as any)?.updateAll(true);
  }

  transformLayout(
    playlists: Playlist[],
    layout: Layout,
    depth = 0
  ): TreeItem[] {
    return layout
      .map<TreeItem>((node, i) => {
        let nodePlaylists = playlists;
        for (const filter of node.filters) {
          nodePlaylists = filter.fn(nodePlaylists.slice(), filter.options);
        }

        let key = `${depth * 100 + i}`;

        if (node.layout.length === 0) {
          if (nodePlaylists.length === 1) {
            key = nodePlaylists[0].id;
          } else {
            // We filter it afterwards.
            return undefined as any;
          }
        }

        return {
          key,
          label: node.title,
          children:
            node.layout.length > 0
              ? this.transformLayout(nodePlaylists, node.layout, depth + 1)
              : undefined
        };
      })
      .filter(n => n !== undefined)
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  mounted() {
    this.selectPlaylist();
  }
}
