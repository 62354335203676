var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},on:{"click:outside":_vm.resetCreateForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4 cc-green-button",attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown},on:{"click":_vm.formActive}},on),[_c('v-icon',{class:{ 'mr-2': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v("mdi-plus-circle")]),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._v(" "+_vm._s(_vm.$t('app.playlists.actions.create'))+" ")]:_vm._e()],2)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('app.playlists.dialog.create.title')))]),_c('v-card-text',[_c('v-form',{ref:"createForm",attrs:{"lazy-validation":false},model:{value:(_vm.createValid),callback:function ($$v) {_vm.createValid=$$v},expression:"createValid"}},[(_vm.admin && _vm.users)?_c('v-select',{attrs:{"items":_vm.users,"dense":"","item-text":"email_address","item-value":"user_id","label":"User","rules":[
            function (v) { return !!v ||
              _vm.$t('app.playlists.dialog.create.validation.fieldRequired'); }
          ],"required":""},model:{value:(_vm.selectedUserId),callback:function ($$v) {_vm.selectedUserId=$$v},expression:"selectedUserId"}}):_vm._e(),_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || _vm.$t('app.playlists.dialog.create.validation.nameRequired'); }
          ],"label":_vm.$t('app.playlists.dialog.create.playlistName'),"required":""},model:{value:(_vm.createPlaylistName),callback:function ($$v) {_vm.createPlaylistName=$$v},expression:"createPlaylistName"}}),_c('v-textarea',{attrs:{"label":_vm.$t('app.playlists.dialog.create.description')},model:{value:(_vm.createDescription),callback:function ($$v) {_vm.createDescription=$$v},expression:"createDescription"}}),(_vm.allowExistingPlaylists)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":4}},[_c('v-checkbox',{attrs:{"label":_vm.$t('app.playlists.dialog.create.fromSourcePlaylist')},model:{value:(_vm.createExistingPlaylist),callback:function ($$v) {_vm.createExistingPlaylist=$$v},expression:"createExistingPlaylist"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":8}},[_c('PlaylistAutocomplete',{attrs:{"playlists":_vm.userPlaylists,"label":_vm.$t('app.playlists.dialog.create.sourcePlaylist'),"dense":"","rules":!_vm.createExistingPlaylist
                  ? undefined
                  : [function (v) { return !!v || _vm.$t('common.validation.fieldRequired'); }],"disabled":!_vm.createExistingPlaylist},model:{value:(_vm.createExistingPlaylistId),callback:function ($$v) {_vm.createExistingPlaylistId=$$v},expression:"createExistingPlaylistId"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.createValid,"loading":_vm.createLoading},on:{"click":_vm.createPlaylist}},[_vm._v(_vm._s(_vm.$t('app.playlists.dialog.create.create')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }