

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { addPlaylist, getTracks, getPlaylist, User } from '@/service/api';
import { Playlist, ClassType } from '@/service/types';

import ClassIcon from '@/components/ClassIcon.vue';
import LengthIcon from '@/components/LengthIcon.vue';

import PlaylistAutocomplete from './PlaylistAutocomplete.vue';
import { errorEventBus } from '../service';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    PlaylistAutocomplete,
    ClassIcon,
    LengthIcon
  }
})
export default class CreateDialog extends Vue {
  createDialog: boolean = false;
  createPlaylistName: string = '';
  createValid: boolean = false;
  createExistingPlaylist: boolean = false;
  createExistingPlaylistId: string = '';
  createLoading: boolean = false;
  createDescription: string = '';

  @Prop({ required: true })
  playlists!: Playlist[];

  @Prop()
  selectedPlaylist?: Playlist;

  @Prop({ type: Boolean, default: true })
  allowExistingPlaylists!: boolean;

  @Prop()
  users?: User[];

  selectedUserId: string = '';

  get userPlaylists() {
    return this.playlists.filter(
      p =>
        p.classTypes.indexOf(ClassType.Custom) === -1 ||
        (p.userId === this.activeUserId ?? '')
    );
  }

  get admin() {
    const userStore = getModule(UserModule, this.$store);
    return userStore.admin;
  }

  get activeUserId() {
    const userStore = getModule(UserModule, this.$store);
    return userStore.selectedUserId;
  }

  formActive() {
    this.createExistingPlaylistId = this.selectedPlaylist?.id ?? '';
  }

  async createPlaylist() {
    const valid = (this.$refs.createForm as any).validate();
    if (!valid) {
      return;
    }

    const tracks: string[] = this.createExistingPlaylist
      ? (await getPlaylist(this.createExistingPlaylistId)).tracks
      : [];

    this.createLoading = true;
    try {
      const res = await addPlaylist(
        {
          name: this.createPlaylistName,
          description: this.createDescription ?? '',
          classTypes: [ClassType.Custom],
          tracks
        } as Playlist,
        this.selectedUserId ?? undefined
      );

      this.$emit('created', res.playlist_id);

      this.resetCreateForm();
      this.createDialog = false;
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.createLoading = false;
    }
  }

  resetCreateForm() {
    (this.$refs.createForm as any).reset();

    this.createExistingPlaylistId = this.selectedPlaylist?.id ?? '';
  }

  @Watch('selectedPlaylist')
  onSelectedPlaylistChanged() {
    this.createExistingPlaylistId = this.selectedPlaylist?.id ?? '';
  }

  created() {
    this.selectedUserId =
      getModule(UserModule, this.$store).selectedUserId ?? '';
  }
}
