import { Playlist } from '../types';
import * as decl from './decl';

const DEFAULT_COLOR = '#009bdf';

const DEFAULT_ICON: decl.Icon = {
  color: '#009bdf',
  data: ''
};

export interface Filter<O = any> {
  fn: (playlists: Array<Playlist>, options?: O) => Array<Playlist>;
  options?: O;
}

export interface Button {
  icon: decl.Icon;
  color: string;
  title: string;
  description: string;
  filters: Array<Filter>;
  compact?: boolean;
  layout: Layout;
}

export type Layout = Array<Button>;

export interface LayoutRoot {
  icons: Record<string, decl.Icon>;
  filters: Record<string, Filter>;
  buttons: Record<string, Button>;
  compact?: boolean;
  layout: Layout;
}

export function resolveRoot(root: decl.LayoutRoot): LayoutRoot {
  const icons = root.icons ?? {};
  const filters = Object.entries(root.filters ?? {}).reduce(
    (filters, [id, filter]) => {
      filters[id] = evaluateFilter(filter);
      return filters;
    },
    {} as Record<string, Filter>
  );

  const buttons: Record<string, Button> = {};

  for (const [id, button] of Object.entries(root.buttons ?? {})) {
    buttons[id] = resolveButton(filters, buttons, icons, button);
  }

  return {
    icons,
    filters,
    buttons,
    compact: root.compact,
    layout:
      root.layout?.map(b => resolveButton(filters, buttons, icons, b)) ?? []
  };
}

function evaluateFilter(filter: decl.Filter): Filter {
  return {
    fn: new Function('playlists', 'options', filter.script) as any,
    options: filter.options
  };
}

function resolveFilter(
  filters: Record<string, Filter>,
  filter: decl.Filter | decl.FilterRef
): Filter {
  if (isFilterRef(filter)) {
    if (filters[filter.id] === undefined) {
      throw new Error(`filter with id ${filter.id} doesn't exist`);
    }
    const f = { ...filters[filter.id] };
    f.options = filter.options ?? f.options;
    return f;
  } else {
    return evaluateFilter(filter);
  }
}

function resolveButton(
  filters: Record<string, Filter>,
  buttons: Record<string, Button>,
  icons: Record<string, decl.Icon>,
  button: decl.Button | decl.ButtonId
): Button {
  if (typeof button === 'string') {
    if (buttons[button] === undefined) {
      throw new Error(`button with id ${button} doesn't exist`);
    }
    return buttons[button];
  }

  let icon;
  if (typeof button.icon === 'string') {
    if (icons[button.icon] === undefined) {
      throw new Error(`icon with id ${button.icon} doesn't exist`);
    }
    icon = icons[button.icon];
  } else {
    icon = button.icon ?? DEFAULT_ICON;
  }

  return {
    color: button.color ?? DEFAULT_COLOR,
    title: button.title ?? 'Menu',
    description: button.description ?? '',
    layout:
      button.layout?.map(b => resolveButton(filters, buttons, icons, b)) ?? [],
    icon,
    compact: button.compact,
    filters: button.filters?.map(f => resolveFilter(filters, f)) ?? []
  };
}

function isFilterRef(
  filter: decl.Filter | decl.FilterRef
): filter is decl.FilterRef {
  return (<decl.FilterRef>filter).id !== undefined;
}
